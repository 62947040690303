import { Nullable } from '@tager/web-core';

import { OptionType } from '@/typings/common';
import { useTypedSelector } from '@/store/store';
import { getBrandsAndModelsItems } from '@/store/reducers/catalog';
import { getYearsOptions } from '@/utils/date';

import { CarSelectsOptions } from './types';

export const useBrandsAndModelsOptions = (
  brand: Nullable<OptionType>
): Nullable<CarSelectsOptions> => {
  const brandsAndModels = useTypedSelector(getBrandsAndModelsItems);

  if (!brandsAndModels || brandsAndModels.length === 0) {
    return null;
  }

  const brands: OptionType[] = brandsAndModels.map(({ name }) => ({
    label: name,
    value: name,
  }));

  const models: OptionType[] =
    brandsAndModels
      .find(({ name }) => name === brand?.value)
      ?.models.map(({ name }) => ({ label: name, value: name })) ?? [];

  return {
    brands,
    models,
    years: getYearsOptions(),
  };
};
